import React from 'react';
import { RiArrowDownDoubleFill, RiArrowUpDoubleFill } from "react-icons/ri";

interface ArrowButtonProps {
  title: string;
  onClick?: () => void;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ title, onClick }) => {
  return (
    <div onClick={onClick} className="hidden lg:flex flex-col items-center justify-center w-20 h-20 rounded-full cursor-pointer hover:opacity-50 transition duration-300 z-20">
      <p className='text-sm'>{title}</p>
      {title === 'TOP' ? <RiArrowUpDoubleFill size={35} /> : <RiArrowDownDoubleFill size={35} />}
    </div>
  );
};

export default ArrowButton;
