import { Route, Routes } from 'react-router-dom';
import BuyStash from '../pages/BuyStash';
import Home from '../pages/Home';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/games" element={<Games />} />
      <Route path="/babylon-toolkit" element={<BabylonToolkit />} /> */}
      <Route path="/buy-stash" element={<BuyStash />} />
    </Routes>
  );
};

export default AppRoutes;
