import { createThirdwebClient } from "thirdweb";
import { arbitrum } from "thirdweb/chains";
import { PayEmbed, ThirdwebProvider } from "thirdweb/react";

const client = createThirdwebClient({ clientId: "bda70fe51a4c7c335f33ec3cfff510d9" });

const BuyStash = () => {
  return (
    <div className='flex justify-center md:block md:justify-normal'>
      <ThirdwebProvider>
        <PayEmbed
          client={client}
          payOptions={{
            prefillBuy: {
              token: {
                address: "0xbEc3eef33eECD66986CF3b2C8C55531a1B67F1F2",
                name: "Stash",
                symbol: "STH",
              },
              chain: arbitrum,
              allowEdits: {
                amount: true, // allow editing buy amount
                token: false, // disable selecting buy token
                chain: false, // disable selecting buy chain
              },
            },
          }}
        />
      </ThirdwebProvider>
    </div>
  );
};

export default BuyStash;
