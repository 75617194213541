import React, { useRef } from 'react';
import ArrowButton from '../Common/ArrowButton';
import LinkButton from '../Common/LinkButton';
import OutSite from '../Common/OutSite';
import { ABOUT } from '../utils/constants';

const Game: React.FC = () => {
  const threejsRef = useRef<HTMLDivElement>(null);
  const burnoutRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);

  const scrollToNextSection = (currentRef: React.RefObject<HTMLDivElement>) => {
    if (currentRef.current) {
      window.scrollTo({
        top: currentRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className="flex flex-col gap-6 md:gap-12 pt-6">
      <div ref={threejsRef} className='bg-primary py-2 pt-4 md:py-4 md:pt-8 flex flex-col text-center gap-4 rounded-xl md:rounded-2xl relative'>
        <div className="flex justify-center items-center gap-4 md:gap-16 pb-2 md:pb-4">
          <img src="./images/threejs.png" className='w-[100px] md:w-[230px] md:h-[80px]' alt="threejs" />
          <img src="./images/arbitrum.png" className='w-[100px] h-[30px] md:w-[230px] md:h-[60px]' alt="arbitrum" />
        </div>
        <div className="w-full px-8 md:px-16 flex justify-center">
          <img src="./images/market1.png" className='md:w-3/4' alt="market1" />
        </div>
        <div className="flex justify-center md:justify-between md:items- md:px-8 pb-4">
          <div></div>
          <LinkButton title={'Play on Desktop Web Browser'} src='https://racer.coinstash.games/' />
          <ArrowButton title='NEXT' onClick={() => scrollToNextSection(burnoutRef)} />
        </div>
        <img src="./images/radial-gradient.png" className='absolute bottom-0 right-0 w-48 lg:visible invisible' alt="" />
      </div>
      <div ref={burnoutRef} className='bg-primary py-2 pt-4 md:py-4 md:pt-8 flex flex-col text-center gap-4 rounded-xl md:rounded-2xl relative'>
        <div className="flex flex-wrap items-center justify-center gap-4 md:gap-8 px-2 md:px-20">
          <div className='flex items-center gap-4'>
            <img className="h-14 w-[100px] md:w-[200px] md:h-[100px]" src="./images/unity.png" alt="unity" />
            <img className="h-8 w-[30px] md:w-[60px]" src="./images/arrow-right.png" alt="arrow-right" />
            <img className="h-12 w-[130px] md:w-[230px] md:h-[80px]" src="./images/babylon-mark.png" alt="babylon" />
          </div>
        </div>
        <div className="flex justify-center">
          <img src="./images/rideshare.png" alt="rideshare" />
        </div>
        <div className="flex justify-center md:justify-between md:items-end md:px-8 pb-4">
          <div></div>
          <LinkButton title={'Play on Desktop Web Browser'} src='https://burnout.coinstash.games/' />
          <ArrowButton title='NEXT' onClick={() => scrollToNextSection(aboutRef)} />
        </div>
        <img src="./images/radial-gradient.png" className='absolute bottom-0 right-0 w-48 lg:visible invisible' alt="" />
      </div>
      <div className="flex justify-center py-4">
        <div className='flex gap-4 md:gap-16'>
          <OutSite imgSrc={'./images/telegram.svg'} outSiteUrl={'https://t.me/coinstash_crypto_club'} />
          <OutSite imgSrc={'./images/discord.svg'} outSiteUrl={'https://discord.gg/crooze-936756571743088651'} />
          <OutSite imgSrc={'./images/x-shape.svg'} outSiteUrl={'https://x.com/coin_stash'} />
          <OutSite imgSrc={'./images/white-paper.svg'} outSiteUrl={'https://coinstash-ecosystem.gitbook.io/coinstash-ecosystem/'} />
        </div>
      </div>
      <div ref={aboutRef} className='bg-primary py-2 pt-4 md:py-4 md:pt-8 flex flex-col text-center gap-4 rounded-xl md:rounded-2xl relative'>
        <span>
          <p className='text-lg md:text-3xl font-bold'>About</p>
        </span>
        <div className='px-8 md:px-48'>
          <div className="h-96 overflow-y-scroll">
            <div className='mr-2 md:mr-12 text-left text-md md:text-2xl' dangerouslySetInnerHTML={{ __html: ABOUT }} />
          </div>
        </div>
        <div className="flex justify-between pl-8 md:pl-40 md:px-8 pb-4">
          <LinkButton title={'Team'} src=' https://coinstash-ecosystem.gitbook.io/coinstash-ecosystem/team' />
          <ArrowButton title='TOP' onClick={scrollToTop} />
        </div>
        <img src="./images/radial-gradient.png" className='absolute bottom-0 right-0 w-48 lg:visible invisible' alt="" />
      </div>
      <div className="flex flex-col md:px-8 md:text-xl gap-4">
        <div className="flex flex-col md:flex-row justify-between items-center md:mr-20">
          <a href="https://www.cosmicforces.com/privacy-policy/" target="_blank" rel="noreferrer"><span>Privacy Policy</span></a>
          <a href="https://www.cosmicforces.com/terms-of-use/" target="_blank" rel="noreferrer">Terms of Use</a>
          <img src="./images/company_title.png" alt="company-title" className='w-[300px] md:w-[520px]' />
        </div>
        <span className=''>Copyright 2024 Cosmic Forces, Inc. All Rights Reserved</span>
      </div>
    </div >
  );
};

export default Game;
