interface BetButtonProps {
  imgSrc: string;
  outSiteUrl: string;
}

function OutSite({ imgSrc, outSiteUrl }: BetButtonProps) {
  return (
    <a href={outSiteUrl} target="_blank" rel="noreferrer">
      <img src={imgSrc} className='w-[60px] md:w-[110px] hover:cursor-pointer hover:scale-125' alt="" />
    </a>
  );
}

export default OutSite;
