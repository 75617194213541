import { TbWorldUp } from "react-icons/tb";

interface LinkButtonProps {
  title: string;
  src: string;
}

function LinkButton({ title, src }: LinkButtonProps) {
  return (
    <a href={src} target="_blank" rel="noreferrer"> <button className="flex items-center bg-red-500 hover:bg-red-600 rounded-3xl text-center self-center px-2 md:px-4 py-1 my-2 mx-2 gap-2 text-xs md:text-base"><TbWorldUp size={20} /><span className='underline'>{title}</span></button></a>
  );
}

export default LinkButton;
